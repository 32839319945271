<template>
  <div
    class="RegisterComplete d-flex justify-content-center align-items-center"
    :class="externalClass"
  >
    <Card
      :authHeading="$t('confirmation.heading')"
      cardClass="p-4"
    >
      <CardHeading :heading="$t('confirmation.cardHeading')" />
      <i18n
        path="confirmation.text"
        class="text-dark"
        tag="p"
      >
        <br class="d-none d-sm-block"/>
        <br class="d-none d-sm-block" />
      </i18n>
    </Card>
  </div>
</template>

<script>
import Card from "@/components/general/Card.vue";
import CardHeading from "../components/general/CardHeading.vue";
export default { components: { Card, CardHeading }, props: ["externalClass"] };
</script>

<style lang="scss">
.RegisterComplete {
  height: 90vh;
  &__card {
    margin-top: 100px;
    max-width: 496px;
    @include xs {
      max-width: 90%;
    }
    &__text {
      @include font(1.125rem, #4e4e4e, 400);
      @include xs {
        font-size: 1.1rem;
      }
    }
  }
}
</style>
